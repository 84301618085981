<template>
  <section class="hero is-medium is-info">
    <div class="hero-body">
      <div class="container">
        <h1 class="title">
          Acerca de
        </h1>
        <h2 class="subtitle">
          Alojamiento de archivos con PHP
        </h2>

        Creado y mantenido por <a target="_blank" class="has-text-warning"
                                  href="https://parzibyte.me/blog">Parzibyte</a> usando
        <b-icon icon="vuejs"></b-icon>
        y
        <b-icon icon="language-php"></b-icon>
        <br>
        <a target="_blank" class="has-text-warning" href="https://parzibyte.me/blog/sigueme/">Puedes seguirme aquí</a> y
        ver
        <a class="has-text-warning" href="https://parzibyte.me/blog/software-creado-por-parzibyte/" target="_blank">más
          proyectos aquí</a>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "AcercaDe"
}
</script>
